@font-face {
  font-family: "Assistant";
  src: url("../assets/fonts/Assistant/Assistant-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

.footerContactItem {
  display: flex;
  align-items: center;
  font-size: 25px;
  gap: 20px;
  color: var(--text-color);
}

.footerContactItem a {
  font-size: 25px;
  font-weight: 500;
  text-decoration: none;
  color: var(--text-color);
  font-family: "Assistant", sans-serif;
  transition: all 0.3s ease-out;
}

.footerContactItem a:hover {
  color: var(--button-hover-color);
}

@media only screen and (max-width: 420px) {
  .footerContactItem {
    font-size: 18px;
  }

  .footerContactItem a {
    font-size: 18px;
  }
}
