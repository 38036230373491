@font-face {
  font-family: "Assistant";
  src: url("../assets/fonts/Assistant/Assistant-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

.copyrightItem {
  font-size: 20px;
  font-weight: 500;
  font-family: "Assistant", sans-serif;
  color: var(--text-color);
}

@media only screen and (max-width: 1220px) {
  .copyrightItem {
    opacity: 0.7;
  }
}

@media only screen and (max-width: 550px) {
  .copyrightItem {
    font-size: 16px;
  }
}

@media only screen and (max-width: 420px) {
  .copyrightItem {
    font-size: 14px;
  }
}

@media only screen and (max-width: 370px) {
  .copyrightItem {
    font-size: 11px;
  }
}
