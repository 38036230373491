.firstSection {
  position: relative;
  width: 80vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.firstSection > .ornament {
  position: absolute;
  left: -80px;
  top: 15%;
  height: 70%;
  width: 2px;
  background: rgba(255, 255, 255, 0.3);
}

.firstSection > .ornament::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-35%, -100%);
  width: 7px;
  height: 7px;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.3);
}

.firstSection > .ornament::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  transform: translate(-35%, 100%);
  background: rgba(255, 255, 255, 0.3);
}

.firstSection > .sectionContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  color: var(--text-color);
}

.firstSection .sectionContent > h1 {
  font-size: 76px;
  font-weight: 600;
  text-align: center;
  text-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
}

.astronauta {
  animation: floating 10s linear infinite;
}

@keyframes floating {
  0% {
    transform: translateY(0) translateX(0);
  }
  25% {
    transform: translateY(-3%) translateX(3%);
  }
  50% {
    transform: translateY(0) translateX(0);
  }
  75% {
    transform: translateY(3%) translateX(-3%);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

@media only screen and (max-width: 1660px) {
  .firstSection {
    width: 90%;
  }

  .firstSection svg {
    transform: scale(0.85);
  }
}

@media only screen and (max-width: 1490px) {
  .firstSection > .ornament {
    left: -40px;
  }
}

@media only screen and (max-width: 1420px) {
  .firstSection {
    width: 100vw;
    flex-direction: column-reverse;
  }

  .firstSection svg {
    height: 350px;
    justify-content: center;
  }

  .firstSection > .ornament {
    display: none;
  }

  .firstSection .sectionContent > h1 {
    font-size: 60px;
    margin: 0 10px;
  }
}
