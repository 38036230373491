footer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid var(--text-color);
  margin-top: 100px;
  /* overflow: hidden; */
}

@media only screen and (max-width: 1220px) {
  footer {
    border-top: 4px solid #9797974d;
    width: 100vw;
  }
}
