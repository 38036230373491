nav.header {
  display: flex;
  gap: 60px;
  align-items: center;
}

nav.footer {
  display: flex;
  gap: 25px;
  align-items: center;
}

@media only screen and (max-width: 1420px) {
  nav.header {
    flex-direction: column;
    gap: 30px;
  }
}
