.sectionText {
  font-size: 25px;
  text-align: center;
  line-height: 1.32em;
  color: var(--text-color);
  overflow-wrap: break-word;
  word-break: break-word;
}

@media only screen and (max-width: 1420px) {
  .sectionText {
    font-size: 20px;
    padding: 0 30px;
  }
}
