.backgroundEllipse {
  position: absolute;
  border-radius: 100%;
  background: radial-gradient(closest-side, var(--primary-color), transparent);
  filter: blur(11.4px);
  z-index: -1;
  pointer-events: none;
}

.backgroundEllipse.small {
  width: 981px;
  height: 879px;
}

.backgroundEllipse.medium {
  width: 1070px;
  height: 1117px;
}

.backgroundEllipse.big {
  width: 1211px;
  height: 1136px;
}
