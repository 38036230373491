@font-face {
  font-family: "Assistant";
  src: url("../assets/fonts/Assistant/Assistant-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Assistant";
  src: url("../assets/fonts/Assistant/Assistant-Medium.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Assistant";
  src: url("../assets/fonts/Assistant/Assistant-Medium.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Assistant";
  src: url("../assets/fonts/Assistant/Assistant-Medium.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Assistant";
  src: url("../assets/fonts/Assistant/Assistant-Medium.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Assistant";
  src: url("../assets/fonts/Assistant/Assistant-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Assistant";
  src: url("../assets/fonts/Assistant/Assistant-Medium.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

.footerTop {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  font-family: "Assistant", sans-serif;
}

.footerTop > .contactWrapper {
  display: flex;
  flex-direction: column;
  gap: 23px;
}

.footerTop > .contactWrapper > .contactItems {
  display: flex;
  gap: 40px;
}

.footerTop > .contactWrapper > .socialmediaItems {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
  gap: 25px;
}

.footerTop > .contactWrapper > .socialmediaItems > p {
  margin-right: 7px;
}

@media only screen and (max-width: 1220px) {
  .footerTop > .contactWrapper > .contactItems {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}
