.button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  padding: 14px 45px;
  border-radius: 20px;
  margin-top: 10px;
  background: var(--button-color);
  color: var(--text-color);
  outline: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.button:hover {
  background: var(--button-hover-color);
}

@media only screen and (max-width: 420px) {
  .button {
    font-size: 18px;
    padding: 15px 55px;
  }
}
