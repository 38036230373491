.headerWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  height: fit-content;
  margin-top: 40px;
}

header {
  position: relative;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  justify-items: center;
  min-width: 1100px;
}

@media only screen and (max-width: 1420px) {
  header {
    position: relative;
    display: flex;
    justify-content: center;
    min-width: 0;
    width: fit-content;
  }
}
