.navButton {
  position: relative;
  color: var(--text-color);
  text-decoration: none;
  transition: all 0.3s ease-out;
  cursor: pointer;
}

.navButton.header {
  font-size: 30px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.navButton.footer {
  font-size: 20px;
  text-transform: uppercase;
}

.navButton:hover {
  color: var(--button-hover-color);
}

@media only screen and (max-width: 550px) {
  .navButton.footer {
    font-size: 16px;
  }
}

@media only screen and (max-width: 420px) {
  .navButton.footer {
    font-size: 14px;
  }
}

@media only screen and (max-width: 370px) {
  .navButton.footer {
    font-size: 11px;
  }
}
