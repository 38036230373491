.serviceTile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 35px 40px;
  border-radius: 20px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  background: rgba(93, 7, 202, 0.5);
  color: var(--text-color);
}

.serviceTile > .tileImage {
  position: relative;
}

.serviceTile > .tileImage svg {
  height: 120px;
}

.serviceTile > .tileContent > h3,
.serviceTile > .tileContent > .tilePrice {
  font-size: 39px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.85);
  text-transform: uppercase;
  display: flex;
  justify-content: center;
}

.serviceTile > .tileContent > h3 {
  margin-bottom: 32px;
}

.serviceTile > .tileContent > ul {
  font-size: 18px;
  font-weight: 400;
  line-height: 35px;
  letter-spacing: 0.1em;
  color: var(--text-color);
}

.serviceTile > .tileContent > .tilePrice {
  display: flex;
  justify-content: center;
  margin: 32px 0 16px 0;
}

@media only screen and (max-width: 1420px) {
  .serviceTile {
    width: fit-content;
  }
}

@media only screen and (max-width: 1220px) {
  .serviceTile > .tileContent > h3,
  .serviceTile > .tileContent > .tilePrice {
    font-size: 35px;
  }
}

@media only screen and (max-width: 1014px) {
  .serviceTile > .tileContent > h3,
  .serviceTile > .tileContent > .tilePrice {
    font-size: 28px;
  }

  .serviceTile > .tileContent > ul {
    font-size: 15px;
    line-height: 18px;
  }
}

@media only screen and (max-width: 920px) {
  .serviceTile {
    padding: 25px 35px;
  }

  .serviceTile > .tileContent > h3,
  .serviceTile > .tileContent > .tilePrice {
    font-size: 28px;
  }

  .serviceTile > .tileContent > ul {
    font-size: 15px;
  }
}

@media only screen and (max-width: 800px) {
  .serviceTile > .tileContent > h3,
  .serviceTile > .tileContent > .tilePrice {
    font-size: 25px;
    font-weight: 700;
  }

  .serviceTile > .tileContent > ul {
    font-size: 12px;
  }
}

@media only screen and (max-width: 420px) {
  .serviceTile > .tileContent > h3,
  .serviceTile > .tileContent > .tilePrice {
    font-size: 17px;
  }
}
