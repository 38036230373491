.menuOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  /* align-items: center; */
  background: #151517;
  z-index: 2000;
  transition: all 0.5s ease-in-out;
}

.menuOverlay.hidden {
  transform: translateX(-300%);
}

.menuOverlay.active {
  transform: translateX(0);
}

.menuOverlay .overlayTop {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.menuOverlay .overlayTop a.logo {
  transform: translateY(0);
}

.menuOverlay .overlayTop .fa-xmark {
  position: absolute;
  top: 50%;
  right: 20px;
  color: var(--text-color);
  font-size: 42px;
}

.menuOverlay .overlayBottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
