.servicesSection {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.servicesSection > .sectionBottom {
  position: relative;
  width: 80vw;
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
