.languageSwitcher {
  position: absolute;
  top: 50%;
  right: 80px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  list-style: none;
  color: var(--text-color);
}

.menuOverlay .languageSwitcher {
  position: relative;
  display: flex;
  justify-content: center;
  top: 0;
  right: 0;
  transform: translate(0, 0);
  flex-direction: row;
  width: fit-content;
  gap: 0;
}

@media only screen and (max-width: 1490px) {
  .languageSwitcher {
    right: 20px;
  }
}
