.languageList {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 120px;
  height: fit-content;
  gap: 20px;
  padding: 19px 0;
  padding-left: 20px;
  top: 100%;
  left: -20px;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out;
}

.menuOverlay .languageSwitcher .languageList {
  position: relative;
  top: 0;
  left: 0;
  flex-direction: row;
  width: fit-content;
  padding: 0;
  opacity: 1;
  visibility: visible;
}

.languageOption.active:hover ~ .languageList,
.languageList:hover {
  display: flex;
  opacity: 1;
  visibility: visible;
}
