.timelineStep {
  position: relative;
  display: flex;
  align-items: flex-start;
  gap: 30px;
  margin-bottom: 30px;
}

.timelineStep > .timelineNumber {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  border-radius: 50%;
  border: 3px solid var(--primary-color);
  background-color: var(--background-color);
}

.timelineStep:not(:first-child)::before {
  content: "";
  position: absolute;
  left: 30px;
  top: -50px;
  transform: translateX(-50%);
  width: 3px;
  height: calc(100% + 15px);
  background: var(--primary-color);
}

.timelineStep:not(:first-child):last-child:before {
  content: "";
  position: absolute;
  left: 30px;
  top: -50px;
  transform: translateX(-50%);
  width: 3px;
  height: calc((100% + 15px) / 3);
  background: var(--primary-color);
}

.timelineStep:not(:last-child)::after {
  content: "";
  position: absolute;
  left: 30px;
  bottom: -75px;
  transform: translateX(-50%);
  width: 3px;
  height: calc(100% + 15px);
  background: var(--primary-color);
}

.timelineStep > .timelineContent {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 12px;
}

.timelineStep > .timelineContent > .timelineTitle {
  font-size: 25px;
  font-weight: 700;
  letter-spacing: 0.1em;
  line-height: 28px;
  color: var(--text-color);
}

.timelineStep > .timelineContent > .timelineText {
  width: 480px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.1em;
  line-height: 28px;
  color: var(--text-color);
}

@media only screen and (max-width: 1220px) {
  .timelineStep {
    flex-direction: column;
    align-items: center;
  }

  .timelineStep::after,
  .timelineStep::before {
    display: none;
  }

  .timelineStep > .timelineContent > .timelineText,
  .timelineStep > .timelineContent > .timelineTitle {
    text-align: center;
  }
}

@media only screen and (max-width: 590px) {
  .timelineStep {
    width: 100vw;
  }

  .timelineStep > .timelineContent > .timelineText {
    width: 100vw;
    padding: 0 20px;
  }
}
