.sectionHeader {
  text-align: center;
  font-size: 49px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.85);
}

.sectionHeader span {
  color: var(--secondary-color);
}

@media only screen and (max-width: 1420px) {
  .sectionHeader {
    padding: 0 30px;
  }
}
@media only screen and (max-width: 1200px) {
  .sectionHeader {
    width: 100vw;
    text-align: center;
    font-size: 40px;
  }
}
