.portfolioSectionSlider {
  /* display: flex; */
  /* justify-content: center; */
  max-width: 100vw;
  /* overflow: hidden; */
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.portfolioSectionSlider .slick-slide > div {
  margin: 0 60px;
}

.portfolioSectionSlider .slick-track {
  max-width: 100vw;
  display: flex !important;
}

.portfolioSectionSlider {
  position: relative;
  width: 100vw;
  display: block;
}

.portfolioSectionSlider .slick-slider {
  position: static !important;
  max-width: 100vw;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
}

.portfolioSectionSlider .slick-slide {
  display: flex !important;
  justify-content: center !important;
}

.portfolioSectionSlider .slick-slider .slick-dots .custom-dot {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: white;
  opacity: 0.3;
  margin: 32px 5px;
}

.portfolioSectionSlider .slick-slider .slick-dots .slick-active .custom-dot {
  opacity: 1;
}
@media only screen and (max-width: 1420px) {
}

@media only screen and (max-width: 1200px) {
  .portfolioSectionSlider .slick-slide > div {
    margin: 0 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .portfolioSectionSlider .slick-track {
    display: flex;
  }
}
