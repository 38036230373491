@font-face {
  font-family: "Assistant";
  src: url("../assets/fonts/Assistant/Assistant-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
.footerSocialItem {
  font-size: 23px;
  font-weight: 500;
  text-decoration: none;
  color: var(--text-color);
  font-family: "Assistant", sans-serif;
  transition: all 0.3s ease-out;
}

.footerSocialItem:hover {
  color: var(--button-hover-color);
}

@media only screen and (max-width: 420px) {
  .footerSocialItem {
    font-size: 16px;
  }
}
