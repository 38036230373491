.servicesTileContainer {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

@media only screen and (max-width: 1520px) {
  .servicesTileContainer {
    width: 100%;
  }
}

@media only screen and (max-width: 1420px) {
  .servicesTileContainer {
    position: relative;
    width: 100vw;
    display: block;
  }

  .servicesTileContainer .slick-slider {
    position: static !important;
    max-width: 100vw;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }

  .servicesTileContainer .slick-slide {
    display: flex !important;
    justify-content: center !important;
  }

  .servicesTileContainer .slick-slider .slick-dots .custom-dot {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background: white;
    opacity: 0.3;
    margin: 32px 5px;
  }

  .servicesTileContainer .slick-slider .slick-dots .slick-active .custom-dot {
    opacity: 1;
  }
}

@media only screen and (max-width: 1000px) {
  .servicesTileContainer .slick-track {
    display: flex;
  }
}
