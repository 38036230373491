.footerBottom {
  width: 85%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid var(--text-color);
  margin-top: 70px;
  padding: 25px 40px;
}

@media only screen and (max-width: 1220px) {
  .footerBottom {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    border-top: 4px solid #9797974d;
    width: 100vw;
    padding: 25px 20px;
  }
}
