.sliderButton {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  border-radius: 100%;
  color: white;
  border: 1px solid white;
  cursor: pointer;
  font-size: 24px;
  z-index: 100;
  transition: all 0.3s ease-out;
}

.sliderButton:hover {
  background: var(--text-color);
  color: var(--background-color);
}

.sliderButton.prev {
  left: 0;
  transform: translate(-150%, -50%);
}

.sliderButton.next {
  right: 0;
  transform: translate(150%, -50%);
}

@media only screen and (max-width: 1490px) {
  .sliderButton.prev {
    transform: translate(100%, -50%);
  }

  .sliderButton.next {
    transform: translate(-100%, -50%);
  }
}

@media only screen and (max-width: 600px) {
  .sliderButton.prev {
    transform: translate(50%, -50%);
  }

  .sliderButton.next {
    transform: translate(-50%, -50%);
  }
}
