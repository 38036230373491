section > .sectionTop {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

@media only screen and (max-width: 1420px) {
  section > .sectionTop {
    width: 100vw;
  }
}
