.typeTile {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  background: rgba(93, 7, 202, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.6);
  border-radius: 20px;
  gap: 32px;
  padding: 30px 15px;
}

.typeTile > .tileTop {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.typeTile > .tileTop > h3 {
  font-size: 25px;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.85);
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.typeTile > .tileTop > h4 {
  font-size: 20px;
  font-weight: 300;
  color: rgba(58, 137, 255, 0.85);
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.typeTile > .tileBottom {
  display: flex;
  gap: 45px;
  align-items: center;
}

.typeTile > .tileBottom > .tileText {
  max-width: 300px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.1em;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 1420px) {
  .typeTile {
    width: fit-content;
    gap: 20px;
  }

  .typeTile > .tileTop {
    width: 80vw;
    align-items: center;
  }
  .typeTile > .tileBottom {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .typeTile > .tileBottom > .tileText {
    line-height: 25px;
    max-width: 800px;
  }
}

@media only screen and (max-width: 1000px) {
  .typeTile > .tileBottom > .tileText {
    max-width: 500px;
  }
}
