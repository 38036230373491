.goToUpButton {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  color: var(--text-color);
  background: var(--background-color);
  font-size: 30px;
  border: 3px solid var(--primary-color);
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.3s ease-out;
}

.goToUpButton:hover {
  background: var(--primary-color);
  border-color: var(--background-color);
}

.goToUpButton.active {
  opacity: 1;
  visibility: visible;
}
