.contactSection {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-top: 100px;
}

.contactSection > .sectionBottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 180px;
  width: 100%;
}

@media only screen and (max-width: 1220px) {
  .contactSection > .sectionBottom {
    gap: 0;
    width: 100vw;
    padding: 0 10px;
  }

  .contactSection > .sectionBottom svg {
    display: none;
  }
}
