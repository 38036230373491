.contactFormContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 500px;
  padding: 35px;
  border-radius: 20px;
  background: rgba(93, 7, 202, 0.3);
  flex-grow: 1;
  -webkit-box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 30px 6px rgba(0, 0, 0, 0.5);
}

.contactFormContainer > h3 {
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0.1em;
  color: rgba(255, 255, 255, 0.85);
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.contactFormContainer form {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
