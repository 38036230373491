@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf")
    format("truetype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Kursywy */
@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-Italic.ttf")
    format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-BoldItalic.ttf")
    format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-BlackItalic.ttf")
    format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-LightItalic.ttf")
    format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-MediumItalic.ttf")
    format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-SemiBoldItalic.ttf")
    format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-ExtraBoldItalic.ttf")
    format("truetype");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Montserrat";
  src: url("../assets/fonts/Montserrat/Montserrat-ThinItalic.ttf")
    format("truetype");
  font-weight: 100;
  font-style: italic;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", sans-serif;
}
:root {
  --text-color: #fff;
  --background-color: #130525;
  --primary-color: #5d07ca;
  --secondary-color: #7000ff;
  --button-color: #611db9;
  --button-hover-color: #8761b7;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #1e073e;
}

::-webkit-scrollbar-thumb {
  background-color: #6340b1;
  border-radius: 20px;
  border: 3px solid #1e073e;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #8652e0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

body {
  background: var(--background-color);
  overflow-x: hidden;
  touch-action: pan-x pan-y;
}

.App {
  position: relative;
  display: flex;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
  overflow: hidden;
}

@media only screen and (max-width: 1490px) {
  .slick-slide > div {
    margin: 0 20px;
  }
}
