.sliderItem {
  position: relative;
  min-height: 320px;
  color: var(--text-color);
  transition: opacity 1s ease-in-out;
  aspect-ratio: 55 / 31;
}

.sliderItem img {
  width: 100%;
  aspect-ratio: 55 / 31;
  /* height: 100%; */
  border: 5px solid #27529f;
  border-radius: 20px;
  -webkit-box-shadow: inset -8px -8px 4px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset -8px -8px 4px 0px rgba(0, 0, 0, 0.25);
  box-shadow: inset -8px -8px 4px 0px rgba(0, 0, 0, 0.25);
}

.sliderItem.active {
  opacity: 1;
}

.sliderItem > .itemData {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  top: 3px;
  left: 4px;
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  transform: scaleY(0);
  transform-origin: bottom;
  border-radius: 20px;
  background: linear-gradient(to top, var(--primary-color), transparent);
  transition: all 0.3s ease-out;
}

.sliderItem:hover > .itemData {
  transform: scaleY(1);
}

.sliderItem > .itemData > h3 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.sliderItem > .itemData > p {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0.1em;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.5);
}

.sliderItem > .itemData > a.itemButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 32px;
  border: 1px solid var(--text-color);
  border-radius: 50px;
  background: var(--primary-color);
  text-decoration: none;
  color: var(--text-color);
  cursor: pointer;
}

.sliderItem > .itemData > a.itemButton:hover {
  background: #763dbf;
}

@media only screen and (max-width: 1490px) {
  .sliderItem {
    /* margin-right: 60px; */
  }
}

@media only screen and (max-width: 460px) {
  .sliderItem {
    min-height: 200px;
  }

  .sliderItem > .itemData > h3 {
    font-size: 2rem;
  }

  .sliderItem > .itemData > p {
    font-size: 14px;
  }
}
