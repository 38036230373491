.mainContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}

.mainContainer .mainWrapper {
  width: 1450px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainContainer .mainWrapper main {
  display: flex;
  flex-direction: column;
  align-items: center;
}
