.messageArea textarea {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;
  padding: 15px 20px;
  border-radius: 20px;
  background: rgba(93, 7, 202, 0.5);
  color: var(--text-color);
  font-size: 16px;
  font-weight: 600;
  outline: none;
  border: none;
  -webkit-box-shadow: inset 4px 2px 6px 0px rgba(0, 0, 0, 0.3),
    inset -2px -2px 6px 0px rgba(224, 224, 224, 0.1);
  -moz-box-shadow: inset 4px 2px 6px 0px rgba(0, 0, 0, 0.3),
    inset -2px -2px 6px 0px rgba(224, 224, 224, 0.1);
  box-shadow: inset 4px 2px 6px 0px rgba(0, 0, 0, 0.3),
    inset -2px -2px 6px 0px rgba(224, 224, 224, 0.1);
  resize: none;
}
