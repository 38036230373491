.stepsSection {
  position: relative;
  width: 80vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  gap: 60px;
  color: var(--text-color);
}

.stepsSection > .sectionBottom {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.stepsSection > .sectionBottom .stepsmale {
  transform: translateY(10%);
  z-index: -1;
}

@media only screen and (max-width: 1520px) {
  .stepsSection > .sectionBottom {
    width: 90%;
  }
}

@media only screen and (max-width: 1220px) {
  .stepsSection > .sectionBottom {
    flex-direction: column;
  }

  .stepsSection > .sectionBottom svg {
    height: 400px;
  }
}

@media only screen and (max-width: 460px) {
  .stepsSection > .sectionBottom svg {
    height: 300px;
  }
}
