a.logo {
  transform: translateY(-15%);
}

@media only screen and (max-width: 640px) {
  a.logo {
    /* transform: translateY(0); */
  }
  a.logo img {
    height: 120px;
  }
}
