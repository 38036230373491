.timelineStepsContainer ul {
  display: flex;
  flex-direction: column;
  gap: 60px;
  list-style: none;
}

@media only screen and (max-width: 1220px) {
  .timelineStepsContainer ul {
    gap: 20px;
  }
}
