.languageOption {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.languageOption img {
  width: 33.5px;
  height: 20px;
  margin-right: 5px;
}

.languageOption.active {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menuOverlay .languageOption {
  padding: 5px;
  border-radius: 7px;
  border: 1px solid transparent;
  transition: all 0.3s ease-out;
}

.menuOverlay .languageOption.active {
  color: var(--button-hover-color);
  border-color: var(--button-hover-color);
}

.languageOption.active .arrow {
  margin-left: 5px;
}

.languageOption:hover:not(.active) {
  color: var(--button-hover-color);
}
